<template>
  <app-overlay
    show
    @clicked="handleUpdateCancel"
  >
    <app-modal @closed="handleUpdateCancel">
      <app-confirm
        :confirm-text="t('ttmt.common.actions.update')"
        :cancel-text="t('ttmt.common.actions.ignore')"
        @cancelled="handleUpdateCancel"
        @confirmed="handleUpdateConfirm"
      >
        <font-awesome-icon
          icon="sync-alt"
          size="5x"
          class="text-gray-300 mb-5"
        />

        <p class="mb-2">
          <strong>
            {{ t('ttmt.common.messages.application_update.new_version_available') }}
          </strong>
          <br>
          {{ t('ttmt.common.messages.application_update.want_to_refresh') }}
        </p>

        <p class="text-xs">
          {{ t('ttmt.common.messages.application_update.if_ignore') }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'

const { t } = useI18n()
const store = useStore()

function handleUpdateCancel() {
  store.commit('serviceWorker/SET_UPDATE_DECLINED', true)
}

function handleUpdateConfirm() {
  store.commit('serviceWorker/SET_UPDATE_APPROVED', true)
  store.commit('loading/ENABLE', true)
  store.commit('loading/SET_TEXT', t('ttmt.common.messages.application_update.updating'))

  // Tell the new service worker to be active
  store.state.serviceWorker.newWorker.postMessage({ action: 'skipWaiting' })
}
</script>

import startApp from '@shared/startApp'

export default ((app, router, store, options = {}) => {
  if (import.meta.env.VITE_USE_SW === 'on' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      // Register service worker
      navigator.serviceWorker.register('/sw.js').then((registration) => {
        // Check if there is an updated service worker
        registration.addEventListener('updatefound', () => {
          // Get the updated service worker instance
          const newWorker = registration.installing

          // once the updated service worker is installed and waiting for activation
          newWorker.addEventListener('statechange', () => {
            switch (newWorker.state) {
              case 'installed':
                // Save it in VueX store
                if (navigator.serviceWorker.controller) {
                  store.commit('serviceWorker/SET_NEW_WORKER', newWorker)
                }
            }
          })
        })

        // End the registration
        return navigator.serviceWorker.ready
      }).then(() => {
        startApp(app, router, store, options)
      })

      // Reload the application when a new service worker is active
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload()
      })
    })
  } else {
    startApp(app, router, store, options)
  }
})

import { createWebHistory } from 'vue-router'

// ---------- HOME ----------

const PageHome = () => import(
  '@app/components/pages/PageHome.vue'
)

// ---------- ERRORS ----------

const PageNotFound = () => import(
  '@app/components/pages/errors/PageNotFound.vue'
)

// ---------- POINTS OF INTEREST ----------

const BasePointOfInterest = () => import(
  '@app/components/pages/points_of_interest/BasePointOfInterest.vue'
)
const PagePointsOfInterestIndex = () => import(
  '@app/components/pages/points_of_interest/PagePointsOfInterestIndex.vue'
)
const PagePointsOfInterestShow = () => import(
  '@app/components/pages/points_of_interest/PagePointsOfInterestShow.vue'
)

// ---------- EXCURSIONS ----------

const BaseExcursion = () => import(
  '@app/components/pages/excursions/BaseExcursion.vue'
)
const PageExcursionsIndex = () => import(
  '@app/components/pages/excursions/PageExcursionsIndex.vue'
)
const PageExcursionsCreate = () => import(
  '@app/components/pages/excursions/PageExcursionsCreate.vue'
)
const PageExcursionsShow = () => import(
  '@app/components/pages/excursions/PageExcursionsShow.vue'
)
const PageExcursionsEdit = () => import(
  '@app/components/pages/excursions/PageExcursionsEdit.vue'
)

// ---------- AUTHENTICATION ----------

const BaseAuth = () => import(
  '@app/components/pages/auth/BaseAuth.vue'
)
const PageLogin = () => import(
  '@app/components/pages/auth/PageLogin.vue'
)
const PageRegister = () => import(
  '@app/components/pages/auth/PageRegister.vue'
)
const PagePasswordForgotten = () => import(
  '@app/components/pages/auth/PagePasswordForgotten.vue'
)
const PagePasswordReset = () => import(
  '@app/components/pages/auth/PagePasswordReset.vue'
)
const PageCredentialVerification = () => import(
  '@app/components/pages/auth/PageCredentialVerification.vue'
)

// ---------- INVITATIONS ----------

const PageInvitationsCreate = () => import(
  '@app/components/pages/invitations/PageInvitationsCreate.vue'
)
const PageInvitationsProfessionalCreate = () => import(
  '@app/components/pages/invitations/PageInvitationsProfessionalCreate.vue'
)

// ---------- USER ----------

const BaseUser = () => import(
  '@app/components/pages/users/BaseUser.vue'
)
const PagePrivateProfileShow = () => import(
  '@app/components/pages/users/PagePrivateProfileShow.vue'
)
const PagePrivateProfileEdit = () => import(
  '@app/components/pages/users/PagePrivateProfileEdit.vue'
)
const PageCredentialEdit = () => import(
  '@app/components/pages/users/PageCredentialEdit.vue'
)
const PagePublicProfileShow = () => import(
  '@app/components/pages/users/PagePublicProfileShow.vue'
)
const PagePrivateProfileDelete = () => import(
  '@app/components/pages/users/PagePrivateProfileDelete.vue'
)

// ---------- NOTIFICATIONS ----------

const PageNotificationsIndex = () => import(
  '@app/components/pages/notifications/PageNotificationsIndex.vue'
)

// ---------- CONTACTS ----------

const BaseContact = () => import(
  '@app/components/pages/contacts/BaseContact.vue'
)
const PageContactsIndex = () => import(
  '@app/components/pages/contacts/PageContactsIndex.vue'
)

// ---------- SHARES ----------

const PageSharesIndex = () => import(
  '@app/components/pages/shares/PageSharesIndex.vue'
)

// ---------- PRIVACY SETTINGS ----------

const PagePrivacySettingsEdit = () => import(
  '@app/components/pages/privacy_settings/PagePrivacySettingsEdit.vue'
)

// ---------- NOTIFICATIONS SETTINGS ----------

const PageNotificationSettingsEdit = () => import(
  '@app/components/pages/notification_settings/PageNotificationSettingsEdit.vue'
)

// ---------- GEOLOC SETTINGS ----------

const PageGeolocSettingsEdit = () => import(
  '@app/components/pages/geoloc_settings/PageGeolocSettingsEdit.vue'
)

// ---------- FOLDERS ----------

const BaseTravellerFolder = () => import(
  '@app/components/pages/traveller_folders/BaseTravellerFolder.vue'
)
const PageTravellerFoldersIndex = () => import(
  '@app/components/pages/traveller_folders/PageTravellerFoldersIndex.vue'
)
const PageTravellerFoldersShow = () => import(
  '@app/components/pages/traveller_folders/PageTravellerFoldersShow.vue'
)

// ---------- PAYMENTS ----------

const BasePayment = () => import(
  '@app/components/pages/payments/BasePayment.vue'
)
const PagePaymentsIndex = () => import(
  '@app/components/pages/payments/PagePaymentsIndex.vue'
)

// ---------- SETTINGS ----------

const PageSettingsIndex = () => import(
  '@app/components/pages/settings/PageSettingsIndex.vue'
)

// ---------- NAVIGATION ----------

const PageNavigation = () => import(
  '@app/components/pages/navigation/PageNavigation.vue'
)

// ---------- FAQS ----------

const BaseFaqCategory = () => import(
  '@app/components/pages/faq_categories/BaseFaqCategory.vue'
)
const PageFaqCategoriesIndex = () => import(
  '@app/components/pages/faq_categories/PageFaqCategoriesIndex.vue'
)
const PageFaqCategoriesShow = () => import(
  '@app/components/pages/faq_categories/PageFaqCategoriesShow.vue'
)

// ---------- CHANGELOGS ----------

const BaseChangelog = () => import(
  '@app/components/pages/changelogs/BaseChangelog.vue'
)
const PageChangelogsIndex = () => import(
  '@app/components/pages/changelogs/PageChangelogsIndex.vue'
)
const PageChangelogsShow = () => import(
  '@app/components/pages/changelogs/PageChangelogsShow.vue'
)

// ---------- ABOUT ----------

const BaseAbout = () => import(
  '@app/components/pages/about/BaseAbout.vue'
)
const PageAboutIndex = () => import(
  '@app/components/pages/about/PageAboutIndex.vue'
)

// ---------- TERMS AND CONDITIONS ----------

const BaseTermsCondition = () => import(
  '@app/components/pages/terms_condition/BaseTermsCondition.vue'
)
const PageTermsConditionIndex = () => import(
  '@app/components/pages/terms_condition/PageTermsConditionIndex.vue'
)

// ---------- PRIVACY POLICY ----------

const BasePrivacyPolicy = () => import(
  '@app/components/pages/privacy_policy/BasePrivacyPolicy.vue'
)
const PagePrivacyPolicyIndex = () => import(
  '@app/components/pages/privacy_policy/PagePrivacyPolicyIndex.vue'
)

// ---------- PARTNERS ----------

const BasePartner = () => import(
  '@app/components/pages/partners/BasePartner.vue'
)
const PagePartnersPartners = () => import(
  '@app/components/pages/partners/PagePartnersPartners.vue'
)
const PagePartnersCustomers = () => import(
  '@app/components/pages/partners/PagePartnersCustomers.vue'
)
const PagePartnersShow = () => import(
  '@app/components/pages/partners/PagePartnersShow.vue'
)

// ---------- SUPPORT REQUEST ----------

const BaseSupportRequest = () => import(
  '@app/components/pages/support_requests/BaseSupportRequest.vue'
)
const PageSupportRequestsIndex = () => import(
  '@app/components/pages/support_requests/PageSupportRequestsIndex.vue'
)
const PageSupportRequestsShow = () => import(
  '@app/components/pages/support_requests/PageSupportRequestsShow.vue'
)
const PageSupportRequestsCreate = () => import(
  '@app/components/pages/support_requests/PageSupportRequestsCreate.vue'
)

// ---------- SUPPORT MESSAGES ----------

const PageSupportMessagesCreate = () => import(
  '@app/components/pages/support_messages/PageSupportMessagesCreate.vue'
)

// ---------- COOKIES ----------

const BaseCookie = () => import(
  '@app/components/pages/cookies/BaseCookie.vue'
)
const PageCookiesShow = () => import(
  '@app/components/pages/cookies/PageCookiesShow.vue'
)

// ---------- CREDITS ----------

const BaseCredit = () => import(
  '@app/components/pages/credit/BaseCredit.vue'
)
const PageCreditIndex = () => import(
  '@app/components/pages/credit/PageCreditIndex.vue'
)

export default {
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: { name: 'home' },
    },
    {
      path: '/home',
      name: 'home',
      component: PageHome,
    },
    {
      path: '/excursions',
      component: BaseExcursion,
      children: [
        {
          path: '',
          name: 'index-excursions',
          component: PageExcursionsIndex,
        },
        {
          path: 'create',
          name: 'create-excursions',
          component: PageExcursionsCreate,
        },
        {
          path: ':id(\\d+)',
          name: 'show-excursions',
          component: PageExcursionsShow,
        },
        {
          path: ':id(\\d+)/edit',
          name: 'edit-excursions',
          component: PageExcursionsEdit,
        },
      ],
    },
    {
      path: '/points-of-interest',
      component: BasePointOfInterest,
      children: [
        {
          path: '',
          name: 'index-points-of-interest',
          component: PagePointsOfInterestIndex,
        },
        {
          path: ':id(\\d+)',
          name: 'show-points-of-interest',
          component: PagePointsOfInterestShow,
        },
      ],
    },
    {
      path: '/auth',
      redirect: { name: 'home' },
      component: BaseAuth,
      children: [
        {
          path: 'login',
          name: 'login',
          component: PageLogin,
        },
        {
          path: 'register',
          name: 'register',
          component: PageRegister,
        },
        {
          path: 'password-forgotten',
          name: 'password-forgotten',
          component: PagePasswordForgotten,
        },
        {
          path: 'password-reset/:uuid',
          name: 'password-reset',
          component: PagePasswordReset,
        },
        {
          path: 'credential-verification',
          name: 'credential-verification',
          component: PageCredentialVerification,
        },
      ],
    },
    {
      path: '/invitation',
      name: 'invitation',
      component: PageInvitationsCreate,
    },
    {
      path: '/invitation-professional',
      name: 'invitation-professional',
      component: PageInvitationsProfessionalCreate,
    },
    {
      path: '/notifications',
      name: 'index-notifications',
      component: PageNotificationsIndex,
    },
    {
      path: '/profile',
      component: BaseUser,
      children: [
        {
          path: '',
          name: 'show-profile',
          component: PagePrivateProfileShow,
        },
        {
          path: 'edit',
          name: 'edit-profile',
          component: PagePrivateProfileEdit,
        },
        {
          path: 'edit-credential',
          name: 'edit-credential',
          component: PageCredentialEdit,
        },
        {
          path: 'delete',
          name: 'delete-profile',
          component: PagePrivateProfileDelete,
        },
      ],
    },
    {
      path: '/users',
      component: BaseUser,
      children: [
        {
          path: ':id(\\d+)',
          name: 'show-users',
          component: PagePublicProfileShow,
        },
      ],
    },
    {
      path: '/contacts',
      component: BaseContact,
      children: [
        {
          path: '',
          name: 'index-contacts',
          component: PageContactsIndex,
        },
      ],
    },
    {
      path: '/shares',
      name: 'index-shares',
      component: PageSharesIndex,
    },
    {
      path: '/geoloc-setting/edit',
      component: PageGeolocSettingsEdit,
      name: 'edit-geoloc-settings',
    },
    {
      path: '/privacy-setting/edit',
      component: PagePrivacySettingsEdit,
      name: 'edit-privacy-settings',
    },
    {
      path: '/notification-setting/edit',
      component: PageNotificationSettingsEdit,
      name: 'edit-notification-settings',
    },
    {
      path: '/folders',
      component: BaseTravellerFolder,
      children: [
        {
          path: '',
          name: 'index-traveller-folders',
          component: PageTravellerFoldersIndex,
        },
        {
          path: ':id(\\d+)',
          name: 'show-traveller-folders',
          component: PageTravellerFoldersShow,
        },
      ],
    },
    {
      path: '/payments',
      component: BasePayment,
      children: [
        {
          path: '',
          name: 'show-payments',
          component: PagePaymentsIndex,
        },
      ],
    },
    {
      path: '/faq-categories',
      component: BaseFaqCategory,
      children: [
        {
          path: '',
          name: 'index-faq-categories',
          component: PageFaqCategoriesIndex,
        },
        {
          path: ':id(\\d+)',
          name: 'show-faq-categories',
          component: PageFaqCategoriesShow,
        },
      ],
    },
    {
      path: '/settings',
      name: 'index-settings',
      component: PageSettingsIndex,
    },
    {
      path: '/navigation',
      name: 'navigation',
      component: PageNavigation,
    },
    {
      path: '/changelogs',
      component: BaseChangelog,
      children: [
        {
          path: '',
          name: 'index-changelogs',
          component: PageChangelogsIndex,
        },
        {
          path: ':id(\\d+)',
          name: 'show-changelogs',
          component: PageChangelogsShow,
        },
      ],
    },
    {
      path: '/about',
      component: BaseAbout,
      children: [
        {
          path: '',
          name: 'index-about',
          component: PageAboutIndex,
        },
      ],
    },
    {
      path: '/terms-condition',
      component: BaseTermsCondition,
      children: [
        {
          path: '',
          name: 'index-terms-condition',
          component: PageTermsConditionIndex,
        },
      ],
    },
    {
      path: '/privacy-policy',
      component: BasePrivacyPolicy,
      children: [
        {
          path: '',
          name: 'index-privacy-policy',
          component: PagePrivacyPolicyIndex,
        },
      ],
    },
    {
      path: '/partners',
      component: BasePartner,
      children: [
        {
          path: '',
          name: 'index-partners',
          component: PagePartnersPartners,
        },
        {
          path: ':id(\\d+)',
          name: 'show-partners',
          component: PagePartnersShow,
        },
      ],
    },
    {
      path: '/customers',
      component: BasePartner,
      children: [
        {
          path: '',
          name: 'index-customers',
          component: PagePartnersCustomers,
        },
      ],
    },
    {
      path: '/support-requests',
      component: BaseSupportRequest,
      children: [
        {
          path: '',
          name: 'index-support-requests',
          component: PageSupportRequestsIndex,
        },
        {
          path: ':id(\\d+)',
          name: 'show-support-requests',
          component: PageSupportRequestsShow,
        },
        {
          path: 'create',
          name: 'create-support-requests',
          component: PageSupportRequestsCreate,
        },
        {
          path: ':id(\\d+)/create',
          name: 'create-support-messages',
          component: PageSupportMessagesCreate,
        },
      ],
    },
    {
      path: '/cookies',
      component: BaseCookie,
      children: [
        {
          path: '',
          name: 'show-cookies',
          component: PageCookiesShow,
        },
      ],
    },
    {
      path: '/credits',
      component: BaseCredit,
      children: [
        {
          path: '',
          name: 'index-credits',
          component: PageCreditIndex,
        },
      ],
    },
    // Keep it at the end because
    // it's a fallback route
    {
      path: '/:pathMatch(.*)*',
      component: PageNotFound,
    },
  ],
}

<template>
  <app-overlay
    show
  >
    <app-modal
      @closed="handleModalClose"
    >
      <!-- Icon -->
      <font-awesome-layers
        class="text-7xl"
      >
        <font-awesome-icon
          icon="location-dot"
          class="text-gray-300 text-7xl"
        />
        <font-awesome-icon
          icon="xmark-circle"
          class="text-red-500 text-2xl"
          style="left: 32px; top: 50px;"
        />
      </font-awesome-layers>

      <!-- Explanations -->
      <p
        class="mt-5 font-bold"
      >
        {{ t('ttmt.user_geoloc_settings.geolocation.error.title') }}
      </p>

      <!-- Permission denied error -->
      <template v-if="errorCode === 1">
        <p
          class="mt-2 text-sm"
        >
          {{ t('ttmt.user_geoloc_settings.geolocation.error.intro') }}
        </p>

        <ul class="text-xs list-disc list-inside mt-1">
          <li>
            {{ t('ttmt.user_geoloc_settings.geolocation.error.allow_browser') }}
          </li>
          <li>
            {{ t('ttmt.user_geoloc_settings.geolocation.error.allow_site') }}
          </li>
        </ul>
      </template>

      <!-- Retry button -->
      <app-button
        v-if="retryCallback"
        :label="t('ttmt.common.actions.retry')"
        class="mt-4"
        @click="handleRetry"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppButton from '@shared/components/ui/AppButton.vue'

const store = useStore()
const { t } = useI18n()

function handleModalClose() {
  store.commit('modals/RESET', 'geoloc_failed')
}

// Callback to execute when retry button is pressed
const retryCallback = computed(() => (
  store.state.modals.geoloc_failed.retry_callback
))

function handleRetry() {
  retryCallback.value()
  handleModalClose()
}

// GeolocationPositionError.code
// See: https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
const errorCode = computed(() => (
  store.state.modals.geoloc_failed.error_code
))
</script>
